<template>
  <a-modal
    v-model:visible="visible"
    :loading="loading"
    :title="$t('common.product_statistics')"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :centered="true"
    width="auto"
    @ok="close"
  >
    <a-table
      :columns="columns"
      :data-source="products"
      :pagination="false"
      :scroll="{ x: 500, y: 400 }"
      :loading="loading"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      size="small"
    >
      <template #footer>
        <a-row justify="end">
          <a-col>
            <a-space :size="50">
              <span>{{ $t('warehouse.product') }}: {{ totalProduct }}</span>
              <span>{{ $t('common.total_product') }}: {{ totalProductNum }}</span>
            </a-space>
          </a-col>
        </a-row>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { Modal, Space, Table, Row, Col } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { getAgentOrderSummary, getTransferToWarehousePlanSummary } from "@/api/modules/consignment/index";

export default defineComponent({
  name: "productStatisticsModal",
  components: {
    AModal: Modal,
    ATable: Table,
    ASpace: Space,
    ARow: Row,
    ACol: Col,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: true,
      totalProduct: 0,
      totalProductNum: 0,
      products: [],
    });

    const columns = [
      {
        dataIndex: "productName",
        width: 150,
        title: () => vueI18n.t('warehouse.product_name'),
      },
      {
        dataIndex: "productNo",
        width: 100,
        title: () => vueI18n.t('common.product_no'),
      },
      {
        dataIndex: "seSku",
        width: 100,
        title: "SESKU",
      },
      {
        dataIndex: "productNum",
        width: 60,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
    ]

    const getData = async (dataType, planId) => {
      try {
        let res;
        switch (dataType) {
          case 1:
            // 配送订单产品统计
            res = await getAgentOrderSummary({ id: planId })
            break;
          case 2:
            // 调拨计划产品统计
            res = await getTransferToWarehousePlanSummary({ id: planId })
            break;
          default:
            break;
        }
        let { result } = res
        if (result) {
          for (const key in result) {
            if (Object.hasOwnProperty.call(state, key)) {
              state[key] = result[key]
            }
          }
        }
      } catch (error) {
        
      } finally {
        state.loading = false
      }
    }
    
    /**
     * @description: 
     * @param {Number} dataType 1为配送订单，2为调拨计划
     * @param {String} planId
     * @return {*}
     */
    const open = (dataType = 1, planId) => {
      state.visible = true
      state.loading = true
      getData(dataType, planId)
    };

    const close = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      columns,
      open,
      close,
    };
  },
});
</script>
