<template>
  <Content>
    <template #contentTitle>
      {{$t('logistics.order_info')}}
    </template>
    <template #contentBody>
      <a-spin :spinning="pageLoading">
        <a-row>
          <a-col :xxl="14"
                 :xl="16"
                 :lg="14"
                 :md="13"
                 :sm="16">
            <a-row :gutter="[72,12]">
              <a-col :span="8">
                <label>{{$t('logistics.consignment_order_no')}}: </label>
                <CPlanNumberItem :no="cache.orderNo"></CPlanNumberItem>
                <a-tag v-if="logisticsType == agentOrderLogisticsTypeEnum.destruction" class="ml-1" color="error">{{ $t('warehouse.destroy_order') }}</a-tag>
              </a-col>
              <a-col :span="8">
                <label>{{ $t("warehouse.creation_time") }}: </label>
                {{ $filters.utcToCurrentTime(cache.creationTime) }}
              </a-col>
              <a-col :span="8">
                <label>{{ $t("warehouse.update_time") }}: </label>
                <span> {{ $filters.utcToCurrentTime(cache.lastModificationTime) }}</span>
              </a-col>
              <a-col :span="8">
                <label>{{$t('logistics.relation_order_no')}}: </label>
                {{cache.relationOrderNo}}
              </a-col>
              <a-col :span="16">
                <label>{{$t('logistics.source_of_sales')}}: </label>
                {{cache.selectedSourceSales}}
              </a-col>
              <a-col :span="8">
                <label>{{$t('logistics.shipments_warehouse')}}: </label>
                {{cache.consignmentWarehouseNo}}
              </a-col>
              <a-col :span="16">
                <label>{{$t('logistics.warehouse_address')}}: </label>
                <span v-if="cache.fromAddress">{{ getAddress(cache.fromAddress) }}</span>
                <span v-else>-</span>
                 <label>({{$t('logistics.contacts')}}: </label>
                {{ getLinkman(cache.fromAddress) }})
              </a-col>
              <a-col :span="8"
                     v-if="cache.recipients">
                <label>{{$t('logistics.recipients')}}: </label>
                {{cache.recipients}}
                <label> ({{$t('logistics.contact_number')}}: </label>
                {{cache.contactNumber??"-"}})
              </a-col>
              <a-col :span="16"
                     v-if="cache.addressData">
                <label>{{$t('logistics.receiving_address')}}: </label>
                <span v-if="cache.addressData">{{ getAddress(cache.addressData) }}</span>
                <span v-else>-</span>
                <span v-if="cache.addressData?.linkMan">({{ getLinkman(cache.addressData) }})</span>
              </a-col>
              <a-col :span="24" 
              v-if="logisticsType == agentOrderLogisticsTypeEnum.seLogistics"
              >
                <label>{{$t('logistics.logistics_track_no')}}: </label>
                <span v-if="cache.logisticsTrackNo"> {{cache.logisticsTrackNo}}</span>
                <span v-else>-</span>
              </a-col>
            </a-row>
          </a-col>
          <a-col :xxl="10"
                 :xl="8"
                 :lg="10"
                 :md="11"
                 :sm="8">
            <div class="mt-3">
              <a-row type="flex">
                <a-col flex="0 0 150px">
                  <a-row style="text-align:center;"
                         :gutter="[0,10]">
                    <a-col :span="24">
                      <a-typography-text strong> {{$t('warehouse.status')}}</a-typography-text>
                    </a-col>
                    <a-col :span="24">
                      <a-typography-text type="success"> {{$t($enumLangkey('agentOrderStatus',agentOrderStatus))}}</a-typography-text>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
        <div>
          <a-table :columns="columns"
                   :data-source="cache.producTableList"
                   :scroll="{ x: 800}"
                   size="small"
                   :pagination="false">
            <template #serialNumber>
              <span>
                {{ $t("warehouse.serial_number") }}
              </span>
            </template>
            <template #productNameAndSeSku>
              <span>
                {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
              </span>
            </template>
            <template #packagingSize>
              <span>
                {{ $t("logistics.business_packaging_size") }}
              </span>
            </template>
            <template #weight>
              <span>
                {{ $t("logistics.weight") }}
              </span>
            </template>
            <template #amount>
              <span>
                {{ $t("logistics.count") }}
              </span>
            </template>

            <template #productNameAndSeSkuCustom="{record }">
              <a-space>
                <div class="table-list-img-common ">
                  <c-image :src="record.picture" />
                </div>
                <div>
                  <div>{{ record.productName}}</div>
                  <div>{{ record.productNo}}</div>
                </div>
              </a-space>
            </template>

            <template #packagingSizeCustom="{record }">
              <span v-if="!record.length||!record.width||!record.height">-</span>
              <span v-else>{{record.length}}x{{record.width}}x{{record.height}}cm</span>
            </template>

            <template #amountCustom="{record }">
              {{record.amount}} Unit
            </template>

            <template #title>
              <a-row justify="end">
                <a-col>
                  <a-button type="primary"
                            ghost
                            @click="handleShowProductStatisticsModal">{{ $t('common.product_statistics') }}</a-button>
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>

        <!-- 运输特征 -->
        <div class="mt-3"
             v-if="logisticsType==agentOrderLogisticsTypeEnum.seLogistics">
          <span>{{$t('warehouse.transport_characteristics')}}:</span>
          <a-tag color="default"
                 class="ml-1"
                 v-for="item in cache.transportCharacteristics"
                 :key="item">
            {{$t($enumLangkey('transportCharacteristics',item))}}
          </a-tag>
        </div>
        <!-- 额外包装材料 -->
        <template v-if="cache.packMaterialType === packMaterialTypeEnum.outPacking">
          <div class="mt-3" v-if="cache.selectedPackagingInfo">
            <span>{{$t('warehouse.additional_packing_material')}}:</span>
            <a-card style="max-width: 500px">
              <div>{{cache.selectedPackagingInfo.name}}-{{!cache.selectedPackagingInfo.length||!cache.selectedPackagingInfo.width||!cache.selectedPackagingInfo.width?"":cache.selectedPackagingInfo.length+'x'+cache.selectedPackagingInfo.width+'x'+cache.selectedPackagingInfo.height+' cm'}}</div>
              <div>{{$t('logistics.max_capacity')}}: {{cache.selectedPackagingInfo.maxVolume}} m<sup>3</sup></div>
              <div>{{$t('warehouse.max_volume_weight')}}: {{cache.selectedPackagingInfo.packBearingQuantity}} kg</div>
              <div>{{$t('logistics.weight_packing_material')}}: {{cache.selectedPackagingInfo.weight}} kg</div>
            </a-card>
          </div>
        </template>
        <template v-else-if="cache.packMaterialType === packMaterialTypeEnum.mergePackaging">
          <div class="mt-3" v-if="cache.mergePackageData">
            <span>{{ $t($enumLangkey('packMaterialType', packMaterialTypeEnum.mergePackaging)) }}:</span>
            <a-card style="max-width: 500px">
              <span>{{ $t('warehouse.merge_packaging_fee') }}: </span>
              <span>{{ cache.mergePackageData.currencySymbol }}{{ $filters.amountToFixed2(cache.mergePackageData.operateFee?.amount) }}</span>
            </a-card>
          </div>
        </template>
        <!-- 包装材料:缠膜 -->
        <template v-if="cache.packMaterialType === packMaterialTypeEnum.stretchFilmPackaging">
          <div class="mt-3" >
            <span>{{$t('warehouse.packing_methods')}}: {{ $t('warehouse.wrapping_film') }}</span>
          </div>
        </template>

        <!-- 物流方案 -->
        <div class=" mt-3"
             v-if="logisticsType==agentOrderLogisticsTypeEnum.seLogistics">
          <!-- 模板计算物流方案 -->
          <div v-if="
          logisticsSchemeList[0].feeCalculationMethod==
          feeCalculationMethodEnum.template">
            <a-table :columns="columnsLogisticsScheme"
                     :data-source="logisticsSchemeList"
                     size="small"
                     :scroll="{ x:true,y:1000}"
                     :pagination="false">

              <template #logisticsScheme>
                <span>
                  {{ $t("warehouse.logistics_scheme") }} / {{ $t("warehouse.logistics_code") }}
                </span>
              </template>

              <template #logisticsWay>
                <span>
                  {{ $t("warehouse.logistics_type") }}
                </span>
              </template>

              <template #deliveryWay>
                <span>
                  {{ $t("warehouse.delivery_way") }}
                </span>
              </template>

              <template #referenceAging>
                <span>
                  {{ $t("warehouse.reference_aging") }}
                </span>
              </template>
              <template #freights>
                <span>
                  {{ $t("warehouse.freight_estimate") }}
                </span>
              </template>

              <template #details>
                <span>
                  {{ $t("common.details") }}
                </span>
              </template>
              <template #optionalServices>
                <span>
                  {{ $t("logistics.selected_service") }}
                </span>
              </template>
              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">{{record.scheme}} / {{record.code}}</div>
                <a-row :gutter=[8,8]>
                  <a-col v-for="item in record.descriptionItems"
                         :key="item">
                    <CheckCircleOutlined class="text-warning mr-1"
                                         :style="{fontSize: '12px'}" />
                    <span style="font-size: 12px; color: rgba(102,102,102,1)">
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>
              <template #logisticsWayCustom="{ record }">
                {{$t($enumLangkey('logisticsWay',record.logisticsWay))}}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
              </template>
              <template #referenceAgingCustom="{ record }">
                <div>
                  {{record.daysMin}}-{{record.daysMax}}
                  {{$t("warehouse.workday")}}
                  ({{$t($enumLangkey('aging',record.channelTimeType))}})
                </div>
              </template>
              <template #freightsCustom="{ record }">
                <div class="text-warning">
                  {{$t('warehouse.average_unit_price')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.averagePrice)}}
                </div>
                <div class="text-warning">
                  {{$t('warehouse.total_prices')}}: {{record.currencySymbol}}{{$filters.amountToFixed2(record.expressFee)}}
                </div>
              </template>
              <template #detailsCustom="{ record }">
                <div v-if="record.logisticsCalcMethod==0">
                  {{$t('logistics.chargeable_weight')}}: {{record.chargeableWeight}}kg
                </div>
                <div v-else>
                  {{$t('logistics.chargeable_volume')}}: {{record.chargeableVolume}}m<sup>3</sup>
                </div>
                <div>
                  {{$t('logistics.volume_weight_coefficient')}}{{record.volumeWeightParam}}
                </div>
              </template>
              <template #optionalServicesCustom="{ record }">
                <template v-if="isSelectedDDP || isSelectedSignFee">
                  <div v-if="isSelectedDDP">
                    DDP ({{ $filters.formatCurrencyAmount(record.currencySymbol, record.logisticsRegionInfo.ddpFee, false) }})
                  </div>
                 <div v-if="record.logisticsRegionInfo.signatureType">
                  <CSignSupported
                            v-model:value="record.logisticsRegionInfo.signatureType"
                            :symbol="record.currencySymbol"
                            :signFeeOptions="
                              record?.logisticsRegionInfo.signFeeDtos
                            "
                            :type="'readonly'"
                          />
                  </div>
                </template>
                <span v-else>-</span>
              </template>

            </a-table>
            <div class="mt-2">
              <a-tag color="warning"
                     v-if="isRemoteArea">{{$t('logistics.remote_site')}}</a-tag>
              <template v-if="isCommercial!=null">
                <a-tag color="warning"
                       v-if="isCommercial">{{$t('logistics.business_site')}}</a-tag>
                <a-tag color="warning"
                       v-else>{{$t('logistics.residence')}}</a-tag>
              </template>
            </div>
            <a-row>
              <a-col :span="5">
                <a-form-item class="mt-3"
                             v-show="isShowVATInput">
                  <template #label>
                    <label>VAT</label>
                  </template>
                  {{VAT}}
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <!-- 卡车物流方案 -->
          <div v-else>
            <a-table :columns="columnsTruckScheme"
                     :data-source="logisticsSchemeList"
                     :scroll="{ x:1000,y:1000}"
                     size="small"
                     :pagination="false">

              <template #quoteOptionsTitle>
                <span>{{$t('logistics.quote_options')}}</span>
              </template>

              <template #logisticsSchemeCustom="{ record }">
                <div class="text-warning">{{record.scheme}} / {{record.code}}</div>
                <a-row :gutter=[8,8]>
                  <a-col v-for="item in record.descriptionItems"
                         :key="item">
                    <CheckCircleOutlined class="text-warning mr-1"
                                         :style="{fontSize: '12px'}" />
                    <span style="font-size: 12px; color: rgba(102,102,102,1)">
                      {{ item }}
                    </span>
                  </a-col>
                </a-row>
              </template>

              <template #logisticsWayCustom="{ record }">
                {{$t($enumLangkey('logisticsWay',record.logisticsWay))}}
              </template>
              <template #deliveryWayCustom="{ record }">
                {{$t($enumLangkey('deliveryWay',record.deliveryWay))}}
              </template>

              <template #quoteOptions="{ record }">
                <div>
                  <a-form-item :label="$t('logistics.quote_type')">
                    {{record.truckBaseInfo.quoteType.name}}
                  </a-form-item>
                  <a-form-item :label="$t('logistics.service_level')"
                               v-if="record.truckBaseInfo.serviceLevel">
                    {{record.truckBaseInfo.serviceLevel.name}}
                  </a-form-item>
                  <a-form-item :label="$t('logistics.additional_options')">

                    <a-popover placement="top"
                               v-if="record.truckBaseInfo.accessorials?.length>0">
                      <template #content>
                        <div style="max-width:350px">

                          <div v-for="(item,index) in record.truckBaseInfo.accessorials"
                               :key="index">
                            {{item.name}}
                            <span v-if="(index+1)!=record.truckBaseInfo.accessorials?.length">, </span>
                          </div>
                        </div>
                      </template>
                      <div style="max-width: 200px;
                                    overflow:hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;">
                        <span v-for="(item,index) in record.truckBaseInfo.accessorials"
                              :key="index">
                          {{item.name}}
                          <span v-if="(index+1)!=record.truckBaseInfo.accessorials?.length">,</span>
                        </span>
                      </div>
                    </a-popover>
                    <span v-else>
                      -
                    </span>

                  </a-form-item>
                </div>

              </template>

              <template #quoteDetails="{ record }">
                <div v-if="record.truckBaseInfo">
                  {{$t('logistics.carrier')}}:
                  {{record.truckBaseInfo.carrierName}}
                  [
                  {{record.truckBaseInfo.currencySymbol}}
                  {{$filters.amountToFixed2(record.truckBaseInfo.totalCharge)}}
                  ,
                  {{ 
                      isNaN(parseInt(record.truckBaseInfo.transitTime)+"")
            ?record.truckBaseInfo.transitTime
            :parseInt(record.truckBaseInfo.transitTime)
            }}
                  {{$t('common.days')}}
                  ]
                </div>
                <span v-else>-</span>
              </template>

            </a-table>
          </div>
        </div>
        <!-- 第三方面单 -->
        <div v-if="logisticsType==agentOrderLogisticsTypeEnum.thirdparty"
             class="mt-3">
          <span>{{$t('warehouse.third_side_list')}}:</span>
          <a-card style="width: 500px">
            <a-row class=""
                   :gutter=[0,24]>
              <a-col :span="24">
                <span class="mr-3">{{$t('warehouse.channel')}}:</span>
                {{$t($enumLangkey('deliveryWay',otherSurface.deliveryType))}}
              </a-col>
              <a-col :span="24">
                <span class="mr-3">{{$t('warehouse.express_sheet')}}:</span>
                <CFileDisplay :fileUrl="otherSurface.surfaceUrl[0]"></CFileDisplay>
              </a-col>
            </a-row>
          </a-card>
        </div>

        <!-- 费用明细 -->
        <div class="mt-5" v-if="totalFee">
          <CFee :fees="fees" :totalFee="totalFee"></CFee>
        </div>
        <!-- 返回 -->
        <a-row type="flex" justify="end" class="mb-5 mt-5">
          <a-col>
            <div style="width: 400px">
              <a-row type="flex" justify="center" :gutter="[24, 12]">
                <a-col class="">
                  <a-button @click="handleBack">{{$t('common.backtrack')}}</a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </a-spin>

      <ProductStatisticsModal ref="productStatisticsModalRef" />
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, } from "vue";
import Content from "../../components/Content.vue";
import {
  Row, Col, Steps, Tag, Typography,
  Table, Input, Popover,
  Button, Form, Spin, Card, Space
} from "ant-design-vue";
import { useRouter, useRoute, } from 'vue-router';
import { useTab } from "../../../hooks/tabs/index";
import CImage from "../../components/CImage.vue"
import CFileDisplay from "../../components/CFileDisplay.vue"
import ProductStatisticsModal from "../components/ProductStatisticsModal.vue"

import { getAddressByLanguageV2, gToKg, cmCubicToM, getLinkman } from "../../../utils/general"
import { useStore } from "vuex";
import {
  getOrderDetails
} from "../../../api/modules/consignment/index"
import {
  agentOrderLogisticsType as agentOrderLogisticsTypeEnum,
  deliveryWay as deliveryWayEnum,
  feeCalculationMethod as feeCalculationMethodEnum,
  packMaterialType as packMaterialTypeEnum,
} from "../../../enum/enum.json";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";
import CFee from "../../components/CFee.vue";
import { useI18n } from "vue-i18n/index";
import CSignSupported from "@/views/components/CSignSupported.vue";


export default ({
  name: "storage_create_in_plan",
  components: {
    CSignSupported,
    ProductStatisticsModal,
    CPlanNumberItem,CFee,
    CImage,
    CFileDisplay,
    Content,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ATag: Tag,
    ACol: Col,
    ATable: Table,
    ATypographyText: Typography.Text,
    AInput: Input,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ACard: Card,
    ASpace: Space,
    APopover: Popover,

  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute, } = useTab();
    const { getters } = useStore();
    const route = useRoute();
    const productStatisticsModalRef = ref();

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
      {
        title: 'SESKU',
        dataIndex: "seSku",
      },
      {
        dataIndex: "packagingSize",
        slots: {
          title: "packagingSize",
          customRender: "packagingSizeCustom",
        },
      },
      {
        dataIndex: "weight",
        slots: {
          title: "weight",
        },
      },
      {
        dataIndex: "amount",
        slots: {
          title: "amount",
          customRender: "amountCustom",
        },
      },
    ];

    const columnsLogisticsScheme = [
      {
        dataIndex: "logisticsScheme",
        slots: {
          title: "logisticsScheme",
          customRender: "logisticsSchemeCustom",
        },
        width: 200,
      },
      {
        dataIndex: "logisticsWay",//物流类型
        slots: {
          title: "logisticsWay",
          customRender: "logisticsWayCustom",//物流名称和基础服务
        },
        width: 100,
      },
      {
        dataIndex: "deliveryWay",//交付方式
        slots: {
          title: "deliveryWay",
          customRender: "deliveryWayCustom",
        },
        width: 100,
      },
      {
        dataIndex: "referenceAging",//参考时效
        slots: {
          title: "referenceAging",
          customRender: "referenceAgingCustom",
        },
        width: 120,
      },
      {
        dataIndex: "freights",//运费
        slots: {
          title: "freights",
          customRender: "freightsCustom",//平均单价和合计
        },
        width: 220,
      },
      {
        dataIndex: "details",//详情
        slots: {
          title: "details",
          customRender: "detailsCustom",//计费重、体积重系数：除600
        },
        width: 150,
      },
      {
        slots: {
          title: "optionalServices",
          customRender: "optionalServicesCustom",
        },
        width: 150,
      }
    ];

    const columnsTruckScheme = [
      {
        title: () => vueI18n.t('warehouse.logistics_scheme') +' / '+ vueI18n.t('warehouse.logistics_code'),
        slots: {
          customRender: "logisticsSchemeCustom",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t('warehouse.logistics_type'),
        slots: {
          customRender: "logisticsWayCustom",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t('warehouse.delivery_way'),
        slots: {
          customRender: "deliveryWayCustom",
        },
        width: 80,
      },
      {
        slots: {
          title: 'quoteOptionsTitle',
          customRender: "quoteOptions",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t('logistics.quote_details'),
        slots: {
          customRender: "quoteDetails",
        },
        width: 100,
      },
    ];

    const state = reactive({
      columnsLogisticsScheme: columnsLogisticsScheme,
      agentOrderLogisticsTypeEnum,
      pageLoading: false,
      agentOrderStatus: null,
      logisticsType: null,
      cache: {
        relationOrderNo: "",//关联订单号
        creationTime: null,
        lastModificationTime: null,
        selectedSourceSales: null,//销售来源
        recipients: "",//收件人
        contactNumber: "",//联系电话
        consignmentWarehouseNo: null,//发货仓
        selectedConsignmentWarehouse: null,//发货仓库Id
        addressData: {},//运送地址
        fromAddress: {},//运送地址
        logisticsTrackNo:null,
        producTableList: [],
        selectedPackagingInfo: {},
        transportCharacteristics: [],
        selectedPackagingType: null,
        packMaterialType: null,
        mergePackageData: null,
      },

      logisticsSchemeList: [],
      logisticsSchemeListTruck: [],
      isSelectedDDP: false,
      isSelectedSignFee: false,
      isShowVATInput: false,
      VAT: "",//税号

      isRemoteArea: null,//是否为偏远地址
      isCommercial: null,//true商业地址 false住宅地址  null不显示

      totalAmount: "",//费用总额
      fees: [],
      totalFee: null,

      otherSurface: {//第3方面单
        deliveryType: null,
        surfaceUrl: null
      }
    })

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const funcOrderDetails = (id) => {
      state.pageLoading = true;
      getOrderDetails({ id: id }).then((res) => {
        let x = res.result;
        if (x) {
          state.logisticsType = x.logisticsType;
          state.cache.orderNo = x.orderNo;
          state.cache.creationTime = x.creationTime;
          state.cache.lastModificationTime = x.lastModificationTime;
          state.cache.relationOrderNo = x.relationOrderNo;
          state.cache.selectedSourceSales = x.saleSourceName;
          state.cache.recipients = x.receiptUserName;
          state.cache.contactNumber = x.contactPhone;
          state.cache.consignmentWarehouseNo = x.warehouseNo;
          state.cache.addressData = x.receiveAddressData;
          state.cache.fromAddress = x.fromAddress;
          state.cache.packMaterialType = x.packMaterialType;
          state.cache.mergePackageData = x.mergePackage;
          state.cache.logisticsTrackNo = x.logisticsTrackNo;
          state.agentOrderStatus = x.status;
          state.fees = x.fee?.fees ?? [];
          state.totalFee = x.fee?.totalFee ?? null;

          state.cache.producTableList = x.products.map((x, index) => {
            return {
              key: index,
              productId: x.productId,
              picture: x.imgUrl,
              productName: x.name,
              productNo: x.no,
              seSku: x.seSku,
              length: x.packingLength,
              width: x.packingWidth,
              height: x.packingHeight,
              weight: x.packingWeight ? gToKg(x.packingWeight) + "kg" : '-',
              amount: x.outCount,
            }
          });

          if (x.packMaterialData) {
            state.cache.selectedPackagingInfo.name = x.packMaterialData.packMaterialName;
            state.cache.selectedPackagingInfo.length = x.packMaterialData.packMaterialLength;
            state.cache.selectedPackagingInfo.width = x.packMaterialData.packMaterialWidth;
            state.cache.selectedPackagingInfo.height = x.packMaterialData.packMaterialHeight;
            state.cache.selectedPackagingInfo.maxVolume = x.packMaterialData.packMaterialVolume ? cmCubicToM(x.packMaterialData.packMaterialVolume) : '-';
            state.cache.selectedPackagingInfo.packBearingQuantity = x.packMaterialData.packBearingQuantity ? gToKg(x.packMaterialData.packBearingQuantity) : '-';
            state.cache.selectedPackagingInfo.weight = x.packMaterialData.packMaterialWeight ? gToKg(x.packMaterialData.packMaterialWeight) : '-';
          } else {
            state.cache.selectedPackagingInfo = null;
          }

          if (x.logisticsType == agentOrderLogisticsTypeEnum.seLogistics) {
            state.isRemoteArea = res.result.logistic.logisticsBaseInfo.isRemoteArea;
            state.isCommercial = res.result.logistic.logisticsBaseInfo.isCommercial;
            state.cache.transportCharacteristics = x.transportCharacteristics;

            let l = x.logistic.logisticsBaseInfo;
            let logistic = {
              key: l.id,
              id: l.id,
              scheme: l.name,
              code: l.code,
              descriptionItems: l.descriptionItems,

              logisticsWay: l.logisticsType,
              deliveryWay: l.deliveryType,

              daysMin: l.daysMin,
              daysMax: l.daysMax,
              channelTimeType: l.channelTimeType,//签收或自提 枚举

              averagePrice: l.averagePrice,//平均费
              expressFee: l.totalFeeWithoutServices,//合计费用
              currencySymbol: l.currencySymbol,//货号符号

              chargeableWeight: l.chargeableWeight ? gToKg(l.chargeableWeight) : '',//计费重
              volumeWeightParam: l.volumeWeightParam,//体积系数
              chargeableVolume: cmCubicToM(l.chargeableVolume),
              logisticsCalcMethod: l.logisticsCalcMethod,
              truckBaseInfo: l.truckBaseInfo,
              feeCalculationMethod: l.feeCalculationMethod,
              logisticsRegionInfo: x.logistic.logisticsRegionInfo,
            }
            state.logisticsSchemeList.push(logistic);
            state.isSelectedDDP = x.logistic.logisticsRegionInfo.isChooseDdp;
            state.isSelectedSignFee = x.logistic.logisticsRegionInfo.isChooseSign;
            state.isShowVATInput = x.logistic.logisticsRegionInfo.vatCode;
            state.VAT = x.logistic.logisticsRegionInfo.vatCode;

          }

          if (x.logisticsType == agentOrderLogisticsTypeEnum.thirdparty) {
            state.otherSurface = x.otherSurface;
          }
        }
      })
        .finally(() => {
          state.pageLoading = false;
        })
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_order_list" });
    }

    const handleShowProductStatisticsModal = () => {
      productStatisticsModalRef.value.open(1, route.params.id)
    }

    onMounted(async () => {
      let id = route.params.id;
      if (id) {
        funcOrderDetails(id);
      }
    });

    return {
      columns,
      columnsTruckScheme,
      ...toRefs(state),
      productStatisticsModalRef,

      getAddress,
      getLinkman,
      handleBack,
      handleShowProductStatisticsModal,
      feeCalculationMethodEnum,
      packMaterialTypeEnum,
    };
  }
})
</script>

<style lang="less" scoped>
.input-error-border,
.input-error-border input {
  border-color: #ff4d4f;
}
</style>